<template>
  <div class="mb-3 card" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
    <v-container fluid>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-4">
            {{ $t('erp.lang_happyHour_sets') }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-row class="mt-4">
              <v-col cols="4">
                <v-text-field
                    v-model="name"
                    :label="$t('erp.lang_happyHourName')"
                    :rules="[v=>!!v || $t('generic.lang_requiredField')]"

                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-row>
                  <v-switch
                      v-model="active"
                      :label="$t('settings.lang_settings_activ')"
                      color="primary"
                      hide-details
                      inset

                  ></v-switch>
                </v-row>
              </v-col>
              <v-col cols="3">
                <v-row>
                  <!-- KassenID -->
                  <v-select
                      v-model="cashierID"
                      :items="filteredCashierIDs"
                      :item-value="'value'"
                      :label="$t('generic.lang_cashierID')"
                      multiple
                      outlined
                  ></v-select>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-chip-group
                  v-model="days"
                  active-class="primary accent-4 white--text"
                  column
                  multiple
                  align="center"
              >
                <v-chip value="1" large>{{ $t('generic.Montag') }}</v-chip>
                <v-chip value="2" large>{{ $t('generic.Dienstag') }}</v-chip>
                <v-chip value="3" large>{{ $t('generic.Mittwoch') }}</v-chip>
                <v-chip value="4" large>{{ $t('generic.Donnerstag') }}</v-chip>
                <v-chip value="5" large>{{ $t('generic.Freitag') }}</v-chip>
                <v-chip value="6" large>{{ $t('generic.Samstag') }}</v-chip>
                <v-chip value="7" large>{{ $t('generic.Sonntag') }}</v-chip>
              </v-chip-group>
            </v-row>
            <v-row>
              <v-col
                  cols="6"
              >
                <BaseTimeInput
                  v-model="from"
                  :label="$t('generic.lang_from')"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                ></BaseTimeInput>
              </v-col>
              <v-col cols="6" align="left">
                <v-row>
                  <v-col cols="4">
                    <v-switch
                        color="primary darken-3"
                        v-model="switch1"
                        :label="$t('erp.lang_happyHourSetPrice')+' '+$store.getters['settings/currencyData']('symbol')"
                        @click="switch1?switch2=false:switch2=true;"
                    ></v-switch>
                  </v-col>
                  <v-col cols="4">
                    <v-switch
                        color="primary darken-3"
                        v-model="switch2"
                        :label="$t('generic.lang_discountInPercent')"
                        @click="switch2?switch1=false:switch1=true;"

                    ></v-switch>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="6"
              >
                <BaseTimeInput
                  v-model="to"
                  :label="$t('generic.lang_till')"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                ></BaseTimeInput>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    :label="$t('erp.lang_happyHourSetPrice')"
                    v-model="discount"
                    :prefix="switch1?  $store.getters['settings/currencyData']('symbol') :`%`  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <div>
                  <h6>{{ $t('erp.lang_happyHourItemgroup') }}: </h6>
                  <v-select
                      v-model="wareGrp"
                      :items="itemGroupHolder"
                      item-text="name"
                      item-value="id"
                      multiple
                      dense
                  ></v-select>
                </div>
              </v-col>
              <v-col align="center" cols="2">
                <v-divider
                    vertical
                ></v-divider>
              </v-col>
              <v-col cols="5">
                <div>
                  <h6>{{ $t('erp.lang_happyHourItems') }}: </h6>
                  <v-select
                      :items="itemGroupHolder"
                      item-text="name"
                      item-value="id"
                      dense
                      v-model="wareGrpId"
                      @input="getItems(wareGrpId)"
                  ></v-select>
                  <v-select
                      v-model="itemsSelected"
                      :items="items"
                      item-text="name"
                      item-value="id"
                      dense
                      multiple
                      :label="$t('erp.lang_items')"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6"></v-col>
              <v-col cols="6" align="right">
                <div>
                  <v-btn
                      text
                      color="error"
                      @click="back"
                  >
                    {{ $t('generic.lang_prev') }}
                  </v-btn>
                  <v-btn
                      @click="deleteData"
                      color="error">{{ $t('generic.lang_delete') }}
                  </v-btn>
                  <v-btn
                      depressed
                      color="green"
                      :dark="!(!name || name.length<1)"
                      :disabled="!name || name.length<1"
                      @click="edit"
                  >
                    {{ $t('generic.lang_edit') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>
    </v-container>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<style>
.v-btn--floating {
  width: 30px !important;
  height: 30px !important;
}

.dataTables_filter {
  display: none;
}

.extra-padding .v-input {
  margin-top: 10px;
}

.extra-padding .v-input input {
  padding-bottom: 12px;
  max-height: 42px;
}

.narrow-list .v-input {
  margin-top: 5px !important;
}

.narrow-list .v-input__slot {
  margin-bottom: 0px !important;
}

.narrow-list label {
  margin-bottom: 0px !important;
}
</style>

<script>
import {ENDPOINTS} from '@/config'
import Datatable from "../../datatable/Datatable";
import ImageCropper from "../../common/imagecropper";
import DatePicker from "../../common/datepicker";
import mixin from "../../../mixins/KeyboardMixIns";
import {mapState} from "vuex";
import {Events} from "@/plugins/events";
import items from "@/store/modules/items";
import BaseTimeInput from '@/components/common/BaseTimeInput.vue';

export default {
  components: {
    Datatable,
    ImageCropper,
    DatePicker,
    BaseTimeInput
  },
  computed: {
    ...mapState([
      'api',
      'cashierIDs/availableCashierIDs'
    ]),
    filteredCashierIDs() {
      return this.cashierIDs.map((cashierID) => {
        return {
          value: cashierID[0],
          text: "Kasse " + cashierID
        };
      });
    }
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      name: "",
      days: [],
      cashierID: [],
      cashierIDs: [],
      from: null,
      to: null,
      menu2: false,
      menu: false,
      modal: false,
      switch1: true,
      switch2: false,
      active: false,
      discount: '',
      wareGrp: [],
      wareGrpId: null,
      items: [],
      itemsSelected: [],
      itemGroupHolder: [],
    }
  },


  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  methods: {
    getCashiers() {
      this.loading = true;

      this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET).then((res) => {
        this.cashierIDs = res.data.cashierIDs;
      }).finally(() => this.loading = false);
    },
    switchActive(switch1, switch2) {
      switch1 = !switch2;
    },
    back() {
      this.$router.push({name: 'erp.baseData.happyHour'});
    },
    async searchPayload() {
      let data = await this.$store.dispatch("itemgroups/getItemgroups");

      //ITEMGROUPS
      data = data.map((itemgroup) => {
        return {
          id: itemgroup.id,
          name: itemgroup.name
        }
      }).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.itemGroupHolder = data;
    },

    loadData() {
      this.axios.post(ENDPOINTS.ERP.HAPPYHOUR.GET, {
        uuid: this.$route.params.id
      }).then((res) => {
        this.name = res.data.formfillData.textFields.name;
        this.active = res.data.formfillData.textFields.isActive;
        this.days = res.data.formfillData.textFields.days;
        this.cashierID = res.data.formfillData.textFields.cashierIdJSON.map(id=>id.toString());
        this.from = res.data.formfillData.textFields.from;
        this.to = res.data.formfillData.textFields.to;
        this.switch1 = res.data.formfillData.textFields.mode == "price" ? true : false;
        this.switch2 = res.data.formfillData.textFields.mode == "percent" ? true : false;
        this.discount = res.data.formfillData.textFields.discountValue;
        this.wareGrp = res.data.formfillData.textFields.wareGroupsList;
        this.itemsSelected = res.data.formfillData.textFields.ItemsList;
        this.wareGrpId = res.data.formfillData.textFields.itemGroupId;

        this.getItems(this.wareGrpId);
      });
    },
    edit() {
      this.loading = true;
      this.axios.post(ENDPOINTS.ERP.HAPPYHOUR.UPDATE, {
        uuid: this.$route.params.id,
        name: this.name,
        isActive: this.active ? 1 : 0,
        days: this.days,
        from: this.from,
        to: this.to,
        cashierID: this.cashierID.map(id=>id.toString()),
        discountType: this.switch1 ? "price" : "percent",
        discountValue: this.discount,
        wareGroupsList: this.wareGrp,
        itemGroupId: this.wareGrpId,
        ItemsList: this.itemsSelected
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_happyHourEdited'),
            color: "success"
          });
          this.back()
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    getItems(id) {
      this.loading = true;
      this.axios.post('/get/erp/itemPerItemgroup/', {
        itemgroupId: id,
      }).then((res) => {
        if (res.data.success) {
          this.items = res.data.items
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('erp.lang_happyHourDeletedHead'),
        text: this.$t('erp.lang_happyHourDeletedBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.$route.params.id);
          }
          this.axios.post(ENDPOINTS.ERP.HAPPYHOUR.DELETE, {
            happyHourUUIDs: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_happyHourDeleted'),
                color: "success"
              });

              this.$router.push('/erp/baseData/happyHour')
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },

  },
  mounted() {
    this.getCashiers();
    this.loadData();
    this.searchPayload();
  },
}
</script>
